<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-03-18 09:45:39
 * @LastEditTime: 2022-03-18 10:56:54
-->
<template>
	<div class="quillEditor">
		<quill-editor class="quillEditor_inner" :style="{'height': editorHeight}" ref="QuillEditor"
			:options="editorOption" v-model="editorData" @change="editorChange" @focus="editorFocus($event)">
		</quill-editor>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload" :http-request="handleCustomRequest"></el-upload>
	</div>
</template>
<script>
	import Quill from "quill";
	import {
		upLoadFile
	} from "@/api/OSS";
	export default {
		props: {
			editorHeight: {
				type: String,
				default: function() {
					return "500px";
				}
			},
			limitSize: {
				//限制多少MB
				type: Number,
				default: 5
			},
			intEditor: {
				type: String,
				default: function() {
					return "";
				}
			},
			enableWrite: {
				type: Boolean,
				default: function() {
					return true;
				}
			},
			upLoadImage: {
				type: Boolean,
				default: function() {
					return false;
				}
			},
		},
		watch: {
			intEditor: {
				handler(val) {
					this.editorData = val
				},
				deep: true
			},
			upLoadImage: {
				handler(val) {
					console.log("upLoadImage", val)
					if (val) {
						this.editorOption = {
							placeholder: "请输入内容......",
							modules: {
								toolbar: {
									container: [
										["bold", "italic", "underline"], // 加粗，斜体，下划线，删除线
										[{
											align: []
										}], // 对齐方式
										["image"]
									],
									handlers: {
										image: value => {
											if (value) {
												document.querySelector(".uploadImage input").click();
												//   console.log(value)
											} else {
												this.quill.format("image", false);
											}
										},
									}
								}
							}
						}
					}
				},
				deep: true,
				immediate: true,
			}
		},
		async mounted() {
			this.$refs.QuillEditor.quill.enable(false);
			this.$nextTick(function() {
				this.$refs.QuillEditor.quill.enable(true);
				this.$refs.QuillEditor.quill.blur();
			});
		},
		data() {
			return {
				editorOption: {
					placeholder: "请输入内容......",
					modules: {
						toolbar: {
							container: [
								["bold", "italic", "underline"], // 加粗，斜体，下划线，删除线
								[{
									align: []
								}], // 对齐方式
							],
							handlers: {
								image: value => {
									if (value) {
										document.querySelector(".uploadImage input").click();
										//   console.log(value)
									} else {
										this.quill.format("image", false);
									}
								},
							}
						}
					}
				},
				upLoadUrl: "",
				editorData: ""
			};
		},
		async mounted() {
			this.$refs.QuillEditor.quill.enable(false);
			this.$nextTick(function() {
				this.$refs.QuillEditor.quill.enable(true);
				this.$refs.QuillEditor.quill.blur();
			});
		},
		methods: {
			editorFocus(e) {
				if (!this.enableWrite) {
					e.enable(false);
				}
			},
			editorChange({
				quill,
				html,
				text
			}) {
				// console.log("editor change!", quill, html, text);
				this.$emit("editorChange", html);
			},
			// 上传按钮   限制图片大小
			beforeUpload(file) {
				console.log("beforeUpload", file);
				const isLt5M = file.size / 1024 / 1024 < this.limitSize;
				if (!isLt5M) {
					this.$message.error("上传文件大小不能超过 5MB!");
					return false;
				}
				let fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				let fileName = file.name;
				let fileType = file.type;
				if (fileSuffix != "png" && fileSuffix != "jpg" && fileSuffix != "jpeg") {
					this.$message({
						message: "上传文件只能是jpg、png格式!",
						type: "warning"
					});
					return false;
				}
			},
			handleCustomRequest(data) {
				console.log("handleCustomRequest", data)
				this.fileData = []
				const formData = new FormData();
				formData.append('file', data.file);
				upLoadFile(formData).then((res) => {
					console.log("上传成功", res.data.data.accessUrl)
					let quill = this.$refs.QuillEditor.quill;
					// 获取光标所在位置
					let range = quill.getSelection(true);
					let indexP = range.index;
					quill.insertEmbed(indexP, "image", res.data.data.accessUrl);
					quill.setSelection(indexP + 1);
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	/deep/.quill-editor {
		display: flex;
		flex-direction: column;
	}

	/deep/img {
		width: 300px;
		height: auto;
	}

	.quillEditor {
		display: flex;
	}

	.quillEditor_inner {
		width: 100%;
	}
</style>
